import { entitiesByKey, getDrivers, getVehicles, mapEntities } from '@data/selectors';
import { sidebarData } from '@features/ui/reducer';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { UiStatePart, VehicleAnalysisStatePart } from 'src/setup/types';
import { Id } from 'src/types';

import { VEHICLE_ROUTE } from '../../constants/routes';
import { DEFAULT_USE_CASE_CONFIG } from './reducer';
import { defaultUseCaseConfig, getUseCaseConfigForKey } from './useCaseConfig';

const vehicleSidebarData = (state: UiStatePart) => sidebarData(state, VEHICLE_ROUTE);

export const entitySidePanelSelection = createSelector(
    [vehicleSidebarData, entitiesByKey, getVehicles, getDrivers],
    (uiSidebarData, dataEntitiesByKey, vehicles, drivers) => {
        const mapEntityByKey = (key: string | undefined) => {
            const baseEntity = key !== undefined ? dataEntitiesByKey[key] : undefined;
            return mapEntities(baseEntity, vehicles, drivers);
        };

        const key = _.get(uiSidebarData, 'id');
        const childKeys = _.get(uiSidebarData, 'childrenIds', []);

        const general = mapEntityByKey(key);
        const children = childKeys.map((id: Id) => mapEntityByKey(id));

        return { ...general, children };
    }
);

export const getUseCaseSetting = (state: VehicleAnalysisStatePart, key: string) => {
    return _.get(state, ['vehicleAnalysis', 'useCaseSettings', key], DEFAULT_USE_CASE_CONFIG);
};

export const getUseCaseConfig = () => defaultUseCaseConfig;

export const getSelectedUseCase = (state: VehicleAnalysisStatePart) => {
    return _.get(state, 'vehicleAnalysis.selectedUseCase', 'overview');
};

export const getSelectedUseCaseConfig = createSelector(
    [getSelectedUseCase, getUseCaseConfig],
    (selectedUseCase, useCaseConfig) => getUseCaseConfigForKey(selectedUseCase, useCaseConfig)
);

export const getSortedBy = (state: VehicleAnalysisStatePart) => {
    return _.get(state, 'vehicleAnalysis.sortedBy') || undefined;
};

export const getOpenRows = (state: VehicleAnalysisStatePart) => {
    return _.get(state, 'vehicleAnalysis.openRows');
};

export const getActiveSummaryTab = (state: VehicleAnalysisStatePart) => {
    return _.get(state, 'vehicleAnalysis.activeSummaryTab');
};
