import { getLocale } from '@common/lang/selectors';
import ConditionalTooltip from '@components/ConditionalTooltip';
import { Dispatch } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { State } from 'src/setup/types';

import { infoNotification } from '../../middlewares/notificationMiddleware';
import RioRangePicker from './RioRangePicker';
import { DateRangeConfig, DateRangePreset } from './types';

const VALID_MINUTES = [0, 15, 30, 45];

const getMinutesDifference = (start: Date, end: Date) => Math.floor((end.getTime() - start.getTime()) / 60000);

const isInvalidTimeInterval = (startDate: Date, endDate: Date) => {
    return (
        getMinutesDifference(startDate, endDate) === 0 ||
        !VALID_MINUTES.includes(startDate.getMinutes()) ||
        !VALID_MINUTES.includes(endDate.getMinutes())
    );
};

const getValidTime = (date: Date) => {
    let newDate = date;
    newDate.setMinutes(Math.round(date.getMinutes() / 15) * 15);
    return newDate;
};

const getValidTimeInterval = (start: Date, end: Date) => {
    const startDate = getValidTime(start);
    const endDate = getValidTime(end);

    if (getMinutesDifference(startDate, endDate) === 0) {
        endDate.setMinutes(endDate.getMinutes() + 15);
    }

    return { startDate, endDate };
};

const getTooltipContent = () => {
    return <FormattedMessage id="presetDisabledBecauseNoData" />;
};

type Props = {
    onSetDateRange: (start: Date, end: Date) => void;
    dateRange?: { from: Date; to: Date };
    dateRangeConfig: DateRangeConfig;
    locale: string;
    showTimeAdjustmentNotification: () => void;
};

export const DateRangePicker = ({
    onSetDateRange,
    dateRange = {
        from: moment()
            .subtract(1, 'week')
            .toDate(),
        to: moment().toDate(),
    },
    dateRangeConfig,
    locale,
    showTimeAdjustmentNotification,
}: Props) => {
    const textProps = {
        textDefault: <FormattedMessage id={'dateRange.placeholder'} />,
        textCustom: <FormattedMessage id={'dateRange.custom'} />,
        textLastCustomRange: <FormattedMessage id={'dateRange.lastCustomRange'} />,
        textFrom: <FormattedMessage id={'dateRange.from'} />,
        textTo: <FormattedMessage id={'dateRange.to'} />,
        textApply: <FormattedMessage id={'apply'} />,
        textCancel: <FormattedMessage id={'cancel'} />,
    };

    const getCustomPresetsText = (preset: DateRangePreset): React.ReactNode => {
        return (
            <ConditionalTooltip enabled={preset.disabled} getTooltipChildren={getTooltipContent}>
                <span>
                    <FormattedMessage id={preset.translationKey} />
                </span>
            </ConditionalTooltip>
        );
    };

    const customPresets = dateRangeConfig.presets.map((preset: DateRangePreset) => ({
        ...preset,
        text: getCustomPresetsText(preset),
    }));

    useEffect(() => {
        const { startDate, endDate } = getValidTimeInterval(dateRange.from, dateRange.to);
        onSetDateRange(startDate, endDate);
    }, [dateRange.from, dateRange.to, onSetDateRange]);

    const onChange = useCallback(
        (start, end) => {
            const { startDate, endDate } = getValidTimeInterval(start.toDate(), end.toDate());

            if (isInvalidTimeInterval(start.toDate(), end.toDate())) {
                showTimeAdjustmentNotification();
            }
            onSetDateRange(startDate, endDate);
        },
        [onSetDateRange, showTimeAdjustmentNotification]
    );

    return (
        <RioRangePicker
            locale={locale}
            onRangeChange={(start: Moment, end: Moment) => onChange(start, end)}
            startValue={dateRange.from}
            endValue={dateRange.to}
            minValue={dateRangeConfig.min}
            maxValue={dateRangeConfig.max}
            customPresets={customPresets}
            customRangeOnly={dateRangeConfig.customRangeOnly}
            hasTimePicker={dateRangeConfig.hasTimePicker}
            dropdownMenuClass={'width-500'}
            {...textProps}
        />
    );
};

const mapStateToProps = (state: State) => ({
    locale: getLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showTimeAdjustmentNotification: () => dispatch(infoNotification('timeAdjustment.info', 8000)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRangePicker);
