import { Text } from '@react-pdf/renderer';
import { extractDriverName } from '@utils/stringFormatters';
import { FormattedMessage } from 'react-intl';

import { Driver, DriverType } from '../../../types';
import driverImage from '../assets/driver.png';
import Label from './Label';
import SeparatedList from './SeparatedList';

const Content = ({ driver }: { driver: Driver }) => {
    if (!driver.type?.trim()) {
        return <>{extractDriverName(driver)}</>;
    }

    switch (driver.type) {
        case DriverType.NO_DRIVER_CARD:
            return <>-</>;
        case DriverType.ANONYMOUS:
            return (
                <>
                    <FormattedMessage id={'anonymized'} />
                </>
            );
        default:
            return <>-</>;
    }
};

export const DriverLabel = ({ drivers = [], expanded = false }: { drivers: Driver[]; expanded?: boolean }) => {
    if (expanded) {
        const driverLabels = drivers.map((driver, index) => (
            <Label textId="drivers" image={driverImage} key={index}>
                <Text style={{ maxLines: 1 }}>
                    <Content driver={driver} />
                </Text>
            </Label>
        ));

        return <SeparatedList>{driverLabels}</SeparatedList>;
    }

    const isSingleDriver = drivers.length === 1;
    const firstDriver = drivers[0];

    const validDriverCardDrivers = drivers.filter(
        driver => ('type' in driver && driver.type !== DriverType.NO_DRIVER_CARD) || !('type' in driver)
    );
    const totalRealDrivers = validDriverCardDrivers.length || '-';

    return (
        <Label textId="drivers" image={driverImage}>
            <Text style={{ maxLines: 2, flex: 1, textOverflow: 'ellipsis' }}>
                {isSingleDriver ? <Content driver={firstDriver} /> : totalRealDrivers}
            </Text>
        </Label>
    );
};

export default DriverLabel;
