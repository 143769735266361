import FleetAssistantDialog from '@features/fleetAssistant/FleetAssistantDialog';
import { toggleFleetDialog } from '@features/fleetAssistant/utils/reducer';
import { shouldShowFleetDialog } from '@features/fleetAssistant/utils/selectors';
import { getFlagFromLocalStorage, saveFlagToLocalStorage } from '@features/ui/utils';
import { Dispatch } from '@reduxjs/toolkit';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import OnboardingTip from '@rio-cloud/rio-uikit/OnboardingTip';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { State } from '../../setup/types';

export const onboardingFlagKey = 'hasSeenFleetAssistantOnboarding';
const hasSeenOnboarding = !getFlagFromLocalStorage(onboardingFlagKey);

const FleetAssistant = (props: { toggleFleetDialog: () => void; isFleetDialogVisible: boolean; fleetSrc: string }) => {
    const { toggleFleetDialog, isFleetDialogVisible, fleetSrc } = props;
    const [showOnboarding, toggleOnboarding] = useState<boolean>(hasSeenOnboarding);

    const handleOnHide = () => {
        toggleOnboarding(false);
        saveFlagToLocalStorage(onboardingFlagKey, true);
    };
    return (
        <ActionBarItem data-test="fleet-assistant-container">
            <ActionBarItem.Icon>
                <div onClick={toggleFleetDialog} role="button" style={{ display: 'inline-block' }}>
                    <OnboardingTip
                        show={showOnboarding}
                        content={<FormattedMessage id="fleetAssistant.onboarding" />}
                        placement={OnboardingTip.BOTTOM}
                        onHide={handleOnHide}
                        className="z-index-max"
                        width={300}
                    >
                        <span className="icon rioglyph rioglyph-robot" />
                    </OnboardingTip>
                </div>
            </ActionBarItem.Icon>
            <React.Fragment>
                <FleetAssistantDialog
                    data-test="fleet-assistant-dialog"
                    show={isFleetDialogVisible}
                    toggleFleetDialog={toggleFleetDialog}
                    fleetSrc={fleetSrc}
                />
            </React.Fragment>
        </ActionBarItem>
    );
};

const mapStateToProps = (state: State) => ({
    isFleetDialogVisible: shouldShowFleetDialog(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleFleetDialog: () => dispatch(toggleFleetDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetAssistant);
