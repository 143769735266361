import { VehicleFuelType, VehicleSegment } from '@api/index';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { SortDirection } from '@utils/sortyByProperty';
import cn from 'classnames';
import _ from 'lodash';
import { memo, ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Vehicle } from 'src/types';

import { Column } from '../../columns/createColumn';
import TableHead from './TableHead';

export const createLoadingData = () => ({ isLoading: true });

export type SortBy = { key: string; order: SortDirection };

const FUEL_VEHICLE_TYPES = [
    VehicleFuelType.DIESEL,
    VehicleFuelType.GAS,
    VehicleFuelType.HYDROGEN,
    VehicleFuelType.UNKNOWN,
];
const ELECTRIC_VEHICLE_FUEL_TYPE = [VehicleFuelType.ELECTRIC];

const COMMON_COLUMNS_FOR_FUEL = [
    'drivingConsumption',
    'idlingConsumption',
    'fuelConsumption',
    'operatingFuelConsumption',
    'distanceFuelConsumption',
    'fuelEfficiency',
];
const COMMON_COLUMNS_FOR_ELECTRIC = [
    'electricAverageDrivingConsumption',
    'electricAverageIdlingConsumption',
    'electricOperatingConsumption',
    'electricAverageOperatingConsumption',
    'electricAverageOperatingConsumptionRange',
    'electricEfficiency',
];
const COMMON_COLUMNS_FOR_FUEL_AND_ELECTRIC = [...COMMON_COLUMNS_FOR_FUEL, ...COMMON_COLUMNS_FOR_ELECTRIC];

const NUMBER_OF_VISIBLE_SCROLLABLE_COLUMNS = 5;

const defaultTableClassNames = [
    'table',
    'table-layout-fixed',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-head-filled',
    'table-hover',
].join(' ');

const CITY_INTERCITY_BUS_KPIS = [
    'discipline.harshAcceleration',
    'discipline.harshBraking',
    'discipline.excessiveIdling',
];

const TableCaptions: React.FunctionComponent<{ column: Column }> = ({ column }) => {
    const className = (column.width ? column.width : 'width-100-lg') + ' width-20pct-sm min-width-100';
    return <col className={className} />;
};

function ExpandableTr<Row extends { level: number }>({
    children,
    onClick,
    row,
    active,
}: {
    onClick: (row: Row, selected: boolean) => void;
    row: Row;
    active: boolean;
    children: ReactNode;
}) {
    const className = cn(row.level === 1 ? 'compactRow' : 'extendedRow', { active, 'cursor-pointer': onClick });
    return (
        <tr onClick={() => onClick(row, active)} className={className}>
            {children}
        </tr>
    );
}

const Td = memo(function Td<Row extends { vehicles?: Vehicle[] }>({
    column,
    row,
    location,
}: {
    column: Column;
    row: Row;
    location?: string;
}) {
    const intl = useIntl();

    const notContainsCityOrIntercityBus = !row.vehicles?.some(
        vehicle => vehicle.segment === VehicleSegment.CITY || vehicle.segment === VehicleSegment.INTER_CITY
    );
    const shouldHideCityAndIntercityKPIValue =
        notContainsCityOrIntercityBus && CITY_INTERCITY_BUS_KPIS.includes(column.labelId);

    const vehicleFuelTypes = row.vehicles?.map(vehicle => vehicle.fuelType) || [];
    const isFleetAnalysisTab = location === 'fleetAnalysis';

    const shouldHideDieselKPIValue =
        isFleetAnalysisTab &&
        vehicleFuelTypes.includes(VehicleFuelType.ELECTRIC) &&
        COMMON_COLUMNS_FOR_FUEL.includes(column.key);

    const shouldHideElectricKPIValue =
        isFleetAnalysisTab &&
        !vehicleFuelTypes.includes(VehicleFuelType.ELECTRIC) &&
        COMMON_COLUMNS_FOR_ELECTRIC.includes(column.key);

    const shouldHideColumnValue =
        shouldHideCityAndIntercityKPIValue || shouldHideDieselKPIValue || shouldHideElectricKPIValue;

    return (
        <td key={column.key} data-test={column.dataField} data-field={intl.formatMessage({ id: column.labelId })}>
            <span className="text-color-darkest">
                {column.formatter(shouldHideColumnValue ? '-' : column.valueExtractor(row), row)}
            </span>
        </td>
    );
});

function Table<
    Row extends { isLoading?: boolean; id: string; level: number; vehicles?: Vehicle[]; warnings?: unknown[] }
>({
    data = [],
    onRowClicked = _.noop,
    filteredColumns = [],
    onOpenChildren = () => {},
    openRows = [],
    onSort = _.noop,
    sortBy = { key: '', order: SortDirection.ASCENDING },
    selectedElements = [],
    upsellVehicles = [],
    sortDisabled = false,
    viewType = TableViewToggles.VIEW_TYPE_TABLE,
    location,
    performAndAdvanceVehicles,
    shouldShowFuelType = false,
    isTruEEnabled = false,
}: {
    data: Row[];
    onRowClicked?: (row: Row | undefined, active: boolean) => void;
    filteredColumns?: Column[];
    onOpenChildren?: (row: Row) => void;
    openRows?: string[];
    onSort?: ({ key, order }: { key: string; order: SortDirection }) => void;
    sortBy?: SortBy;
    selectedElements?: string[];
    upsellVehicles?: Vehicle[];
    sortDisabled?: boolean;
    viewType?: string;
    location?: string;
    performAndAdvanceVehicles?: string[];
    shouldShowFuelType?: boolean;
    isTruEEnabled?: boolean;
}) {
    const isFleetAnalysisTab = location === 'fleetAnalysis';

    const [firstVisibleColumnIndex, setFirstVisibleColumnIndex] = useState(0);
    const lastVisibleColumnIndex = firstVisibleColumnIndex + NUMBER_OF_VISIBLE_SCROLLABLE_COLUMNS;

    const containsCityOrInterCityBus = data.some(row =>
        row.vehicles?.some(
            vehicle => vehicle.segment === VehicleSegment.CITY || vehicle.segment === VehicleSegment.INTER_CITY
        )
    );

    const fuelTypesInData: VehicleFuelType[] = [
        ...new Set(
            data
                .map(row => row.vehicles?.map(vehicle => vehicle.fuelType))
                .flat()
                .filter(Boolean)
        ),
    ] as VehicleFuelType[];
    const containsEletricVehicle = fuelTypesInData.some(fuelInData => ELECTRIC_VEHICLE_FUEL_TYPE.includes(fuelInData));
    const containsFuelVehicle = fuelTypesInData.some(fuelInData => FUEL_VEHICLE_TYPES.includes(fuelInData));

    let columnsToBeShown = containsCityOrInterCityBus
        ? filteredColumns
        : filteredColumns.filter(column => !CITY_INTERCITY_BUS_KPIS.includes(column.labelId));

    const containsBothFuelTypesVehicles = containsEletricVehicle && containsFuelVehicle;
    const containsCommonDieselAndElectricColumns = filteredColumns.some(col =>
        COMMON_COLUMNS_FOR_FUEL_AND_ELECTRIC.includes(col.key)
    );

    const shouldRenderScrollableColumns =
        isFleetAnalysisTab &&
        isTruEEnabled &&
        containsBothFuelTypesVehicles &&
        containsCommonDieselAndElectricColumns &&
        viewType === TableViewToggles.VIEW_TYPE_TABLE;

    // TODO: check with Gabriel other table views (list and cards, because it is not possible to choose which column to show to each vehicle)
    if (
        isFleetAnalysisTab &&
        isTruEEnabled &&
        !containsBothFuelTypesVehicles &&
        containsCommonDieselAndElectricColumns
    ) {
        if (containsEletricVehicle) {
            columnsToBeShown = columnsToBeShown.filter(column => !COMMON_COLUMNS_FOR_FUEL.includes(column.key));
        }
        if (containsFuelVehicle) {
            columnsToBeShown = columnsToBeShown.filter(column => !COMMON_COLUMNS_FOR_ELECTRIC.includes(column.key));
        }
    }

    const formatExpander = useCallback(
        (row: Row) => {
            if (row.isLoading) {
                return (
                    <div className="`btn btn-muted btn-icon-only display-inline-block">
                        <Spinner />
                    </div>
                );
            }
            if (_.get(row, 'level', 1) >= 2) {
                return null;
            } else if (_.get(row, 'childrenCount', 0) < 2) {
                return <div className={'cursor-default btn btn-muted btn-icon-only'} />;
            }

            const entityReference = row.id;
            const isOpen = openRows.includes(entityReference);
            const classNames = `formatExpander btn btn-muted btn-icon-only animate ${isOpen ? 'open' : ''}`;
            return (
                <div
                    className={classNames}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onOpenChildren(row);
                    }}
                >
                    <span className="rioglyph rioglyph-chevron-down" />
                </div>
            );
        },
        [onOpenChildren, openRows]
    );

    const selectedItemAsMap = _.keyBy(selectedElements);

    function onSelectionChange({ id }: { id: string }, active: boolean) {
        const row = _.find(data, { id }) as Row;
        onRowClicked(row, active);
    }

    function handleSortChange(event: React.MouseEvent<HTMLTableHeaderCellElement>) {
        if (event.currentTarget instanceof Element) {
            const clickedKey = event.currentTarget.getAttribute('data-sortby');
            const { key, order } = sortBy;
            if (!clickedKey) {
                return;
            }
            // the default case when we sort by something new
            if (key !== clickedKey) {
                onSort({ key: clickedKey, order: SortDirection.ASCENDING });
                return;
            }
            // we're already sorting so invert
            if (order === SortDirection.DESCENDING) {
                onSort({ key: clickedKey, order: SortDirection.ASCENDING });
            } else {
                onSort({ key: clickedKey, order: SortDirection.DESCENDING });
            }
        }
    }

    const tableClassNames =
        defaultTableClassNames +
        (viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD ? ' table-cards table-single-card' : '') +
        (viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS ? ' table-cards table-multi-cards' : '');

    const handleLeftScrollableArrow = useCallback(() => {
        if (firstVisibleColumnIndex > 0) {
            setFirstVisibleColumnIndex(prevValue => prevValue - 1);
        }
    }, [firstVisibleColumnIndex]);

    const handleRightScrollableArrow = useCallback(() => {
        if (lastVisibleColumnIndex < columnsToBeShown.length) {
            setFirstVisibleColumnIndex(prevValue => prevValue + 1);
        }
    }, [columnsToBeShown.length, lastVisibleColumnIndex]);

    return (
        <div className="table-responsive">
            {data.length || upsellVehicles.length ? (
                <table className={tableClassNames}>
                    <colgroup>
                        {shouldRenderScrollableColumns && <col className="table-action min-width-50" />}
                        {columnsToBeShown.map((column, columnIndex) => {
                            if (
                                !shouldRenderScrollableColumns ||
                                (shouldRenderScrollableColumns &&
                                    columnIndex >= firstVisibleColumnIndex &&
                                    columnIndex < lastVisibleColumnIndex)
                            ) {
                                return <TableCaptions key={column.key} column={column} />;
                            } else {
                                return <></>;
                            }
                        })}
                        {shouldRenderScrollableColumns && <col className="table-action min-width-50" />}
                        <col className="table-action" />
                    </colgroup>
                    <thead>
                        <tr>
                            {shouldRenderScrollableColumns && (
                                <th
                                    key="pagination-left"
                                    data-test="pagination-left"
                                    className="table-action"
                                    onClick={handleLeftScrollableArrow}
                                >
                                    <button
                                        type="button"
                                        disabled={firstVisibleColumnIndex === 0}
                                        className="btn btn-muted btn-icon-only width-100pct"
                                    >
                                        <span className="rioglyph rioglyph-chevron-left" />
                                    </button>
                                </th>
                            )}
                            {columnsToBeShown.map((column, columnIndex) => {
                                if (
                                    !shouldRenderScrollableColumns ||
                                    (shouldRenderScrollableColumns &&
                                        columnIndex >= firstVisibleColumnIndex &&
                                        columnIndex < lastVisibleColumnIndex)
                                ) {
                                    return (
                                        <TableHead
                                            key={column.key}
                                            column={column}
                                            hasSortActive={sortBy.key === column.key}
                                            sortDirection={sortBy.order}
                                            handleSortChange={handleSortChange}
                                            sortDisabled={sortDisabled}
                                            location={location}
                                            shouldShowFuelType={
                                                shouldRenderScrollableColumns &&
                                                COMMON_COLUMNS_FOR_FUEL_AND_ELECTRIC.includes(column.key)
                                            }
                                        />
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                            {shouldRenderScrollableColumns && (
                                <th
                                    key="pagination-right"
                                    data-test="pagination-right"
                                    className="table-action"
                                    onClick={handleRightScrollableArrow}
                                >
                                    <button
                                        type="button"
                                        disabled={lastVisibleColumnIndex >= columnsToBeShown.length}
                                        className="btn btn-muted btn-icon-only width-100pct"
                                    >
                                        <span className="rioglyph rioglyph-chevron-right" />
                                    </button>
                                </th>
                            )}
                            <th className="table-action" />
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => {
                            const id = row.id;
                            return (
                                <ExpandableTr
                                    key={id || index}
                                    onClick={onSelectionChange}
                                    row={row}
                                    active={!!selectedItemAsMap[id]}
                                >
                                    {shouldRenderScrollableColumns && <td></td>}
                                    {columnsToBeShown.map((column, columnIndex) => {
                                        if (
                                            !shouldRenderScrollableColumns ||
                                            (shouldRenderScrollableColumns &&
                                                columnIndex >= firstVisibleColumnIndex &&
                                                columnIndex < lastVisibleColumnIndex)
                                        ) {
                                            return (
                                                <Td key={column.key} column={column} row={row} location={location} />
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}
                                    {shouldRenderScrollableColumns && <td></td>}
                                    <td key="expander">{formatExpander(row)}</td>
                                </ExpandableTr>
                            );
                        })}
                        {viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && <EmptyPlaceholders />}
                    </tbody>
                </table>
            ) : (
                <NotFoundState headline="Nothing found" message="Please refine your search" />
            )}
        </div>
    );
}

function EmptyPlaceholders() {
    // We use this in order to fix a flex issue with the different table displays
    return (
        <>
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />

            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
        </>
    );
}

export default Table;
