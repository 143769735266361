import { getSignal, Id } from '@api/index';
import ErrorBoundary from '@components/ErrorBoundry';
import MultipleVehiclesSelectedState from '@components/MultipleVehiclesSelectedState';
import SectionTitle from '@components/SectionTitle';
import SimpleErrorState from '@components/SimpleErrorState';
import usePerformData from '@data/hooks/usePerformData';
import { Loadable, LoadableType } from '@data/loadable';
import BatteryDetails from '@features/battery/BatteryDetails';
import { BATTERY_DETAILS_REQUEST_ATTRIBUTES } from '@features/battery/queries';
import { EntityType } from '@features/foresightedDriving/types';
import EmptyOpConState from '@features/opcon/EmptyOpConState';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { getColumn } from '@utils/columns';
import toPercent from '@utils/toPercent';
import { Variables } from '@utils/useQuery';
import _ from 'lodash';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { DateRange, HydratedEntity, PerformSegmentBy, RawDriver } from '../../types';
import BatteryProvider, { BatteryContext, Status } from './BatteryProvider';
import ChargeGraph from './ChargeGraph';
import { Details, StateOfChargeDataPoint, StateOfChargeDataPointFiltered } from './types';

const BATTERY_DETAILS_NAMES = ['drivetrain', 'epto', 'aircompressor', 'cooling', 'hvac', 'vpowersupply'];

const StateOfCharge = ({
    stateOfCharges,
    hasStateOfChargeRequestFailed,
    hasStateOfChargeInfo,
    dateRange,
}: {
    stateOfCharges: StateOfChargeDataPointFiltered[] | undefined;
    hasStateOfChargeRequestFailed: boolean;
    hasStateOfChargeInfo: boolean;
    dateRange: DateRange;
}) => {
    if (hasStateOfChargeRequestFailed) {
        return <SimpleErrorState fullWidth className="padding-top-10 padding-bottom-20" data-test="soc-error-state" />;
    }
    if (!hasStateOfChargeInfo || stateOfCharges === undefined) {
        return <EmptyOpConState className="padding-top-10 padding-bottom-0" />;
    }

    return <ChargeGraph stateOfCharges={stateOfCharges} dateRange={dateRange} />;
};

const getBatteryDetailsInfo = (batteryDetail: string, allData: HydratedEntity | Details) => {
    const name = batteryDetail.charAt(0).toLowerCase() + batteryDetail.slice(1);

    const consumptionColumn = getColumn(`${batteryDetail}Consumption`);
    const consumption = consumptionColumn?.formatter(consumptionColumn?.valueExtractor(allData), allData);

    const columnName = batteryDetail === 'energyEfficiency' ? 'electricEfficiency' : `${batteryDetail}ConsumptionPerKm`;

    const perKmColumn = getColumn(columnName);
    const perKm = perKmColumn?.formatter(perKmColumn?.valueExtractor(allData), allData);

    const fullConsumptionField = 'totalPowerConsumption';

    const totalConsumptionColumn = getColumn(fullConsumptionField);
    const totalConsumption = totalConsumptionColumn?.valueExtractor(allData) as number;
    const consumption_kWh = (consumptionColumn?.valueExtractor(allData) as number) || 0;
    const consumption_kWh_format = perKmColumn?.formatter(consumptionColumn?.valueExtractor(allData), allData);

    const percentage = toPercent(consumption_kWh, totalConsumption, 0);

    return { name, consumption, perKm, consumption_kWh, consumption_kWh_format, percentage };
};

const success = (d: LoadableType<EntityType>) => <>{d}</>;
const failure = () => <SimpleErrorState fullWidth />;
const loading = () => <Spinner />;
const unknown = () => null;

const renderStateOfChargeComponent = (
    stateOfCharge: { status: Status; data?: StateOfChargeDataPoint[] | undefined },
    hasStateOfChargeInfo: boolean,
    hasStateOfChargeRequestFailed: boolean,
    dateRange: DateRange
) => {
    let mappedStateOfCharge = stateOfCharge.data;
    const hasBatteryDetailsInfo = !_.isEmpty(stateOfCharge.data) && typeof stateOfCharge.data !== 'undefined';
    if (hasBatteryDetailsInfo) {
        mappedStateOfCharge = stateOfCharge.data
            ?.map(entry => {
                const energyConsumption = getBatteryDetailsInfo('totalPower', entry.details);

                return {
                    ...entry,
                    filteredDetails: {
                        details: BATTERY_DETAILS_NAMES.map(batteryDetailId =>
                            getBatteryDetailsInfo(batteryDetailId, entry.details)
                        ),
                        energyConsumption: energyConsumption,
                    },
                };
            })
            .flat();
    }
    return (
        <ErrorBoundary>
            <SectionTitle title="stateOfChargeMonitor" icon="rioglyph-error-sign" />
            <StateOfCharge
                stateOfCharges={mappedStateOfCharge}
                hasStateOfChargeInfo={hasStateOfChargeInfo}
                hasStateOfChargeRequestFailed={hasStateOfChargeRequestFailed}
                dateRange={dateRange}
            />
        </ErrorBoundary>
    );
};

export const Battery = ({
    data,
    vehicleIds,
    dateRange,
}: {
    data: LoadableType<HydratedEntity>;
    vehicleIds: Id[];
    dateRange: DateRange;
}) => {
    const { stateOfCharge } = useContext(BatteryContext);

    if (vehicleIds.length > 1) {
        return <MultipleVehiclesSelectedState messageKey="battery.multipleVehiclesSelectedMessage" />;
    }

    if (stateOfCharge.status === Status.LOADING) {
        return <Spinner />;
    }

    const hasStateOfChargeRequestFailed = stateOfCharge.status === Status.FAILED;

    if (hasStateOfChargeRequestFailed) {
        return <SimpleErrorState fullWidth className="padding-bottom-20" data-test="battery-error-state" />;
    }

    const hasStateOfChargeInfo = stateOfCharge.status === Status.SUCCESS && (stateOfCharge.data || []).length > 0;

    if (!hasStateOfChargeRequestFailed && !hasStateOfChargeInfo) {
        return <EmptyOpConState className="padding-bottom-20" />;
    }

    const StateOfChargeComponent = renderStateOfChargeComponent(
        stateOfCharge,
        hasStateOfChargeInfo,
        hasStateOfChargeRequestFailed,
        dateRange
    );

    const StateOfChargeComponentWithEmptyState = (
        <div data-test="battery">
            {StateOfChargeComponent}
            <SectionTitle title="energyConsumption" icon="rioglyph rioglyph-battery-level-low" />
            <EmptyState headline={<FormattedMessage id="noData" />} fullWidth />
        </div>
    );

    const mappedData = Loadable.map(data, entity => {
        const hasBatteryDetailsInfo =
            !_.isEmpty(entity) && !_.isUndefined(getSignal(entity, 'totalPowerConsumption', undefined));

        if (entity && hasBatteryDetailsInfo) {
            const batteryDetails = BATTERY_DETAILS_NAMES.map(batteryDetailId =>
                getBatteryDetailsInfo(batteryDetailId, entity)
            ).filter(batteryDetail => batteryDetail.consumption !== '-');

            const totalConsumption = getBatteryDetailsInfo('totalPower', entity);
            const energyEfficiencyPerKm = getBatteryDetailsInfo('energyEfficiency', entity);

            const groupedBatteryDetails = groupBatteryDetails(batteryDetails);

            return (
                <div data-test="battery">
                    {StateOfChargeComponent}
                    <div className="margin-top-20">
                        <SectionTitle title="energyConsumption" icon="rioglyph rioglyph-battery-level-low" />
                        <div className="margin-bottom-25 padding-bottom-25">
                            <div className="border-style-solid border-width-1 border-color-light rounded padding-20">
                                <table className="table margin-bottom-0" data-cy="battery-tab-table">
                                    <colgroup>
                                        <col className="width-40pct width-30pct-lg" />
                                        <col className="width-40pct width-30pct-lg" />
                                    </colgroup>
                                    <tbody className="no-border">
                                        <tr key="battery-active">
                                            <td className="border-top-0 padding-top-0 padding-left-0">
                                                <div className="display-flex gap-10 align-items-center">
                                                    <span className="text-medium text-color-dark text-size-16">
                                                        <FormattedMessage id="totalEnergyConsumption" />:
                                                    </span>
                                                    <span className="text-medium text-size-h3 text-color-highlight">
                                                        {totalConsumption.consumption}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="border-top-0 padding-top-0 padding-right-0 text-right">
                                                <span className="text-normal text-color-gray text-size-16 padding-right-4">
                                                    <FormattedMessage id="energyEfficiency" />:
                                                </span>
                                                <span className="text-medium text-size-16 text-color-dark">
                                                    {energyEfficiencyPerKm.perKm}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr className="border border-top-only" key="program-efficiency">
                                            <td className="padding-top-10 padding-bottom-0" />
                                            <td className="padding-top-10 padding-bottom-0 padding-right-0" />
                                        </tr>
                                        {groupedBatteryDetails.map((group, index) => (
                                            <tr className="border-top-only" key={`group-${index}`}>
                                                {group.map(item => (
                                                    <td
                                                        className="border-top-0 padding-bottom-0"
                                                        key={`program-${item.name}`}
                                                    >
                                                        <BatteryDetails
                                                            name={`batteryDetails.${item.name}`}
                                                            consumption={item.consumption}
                                                            percentage={item.percentage}
                                                            perKm={item.perKm}
                                                            progressBarColor="text-color-status-resting"
                                                        />
                                                    </td>
                                                ))}
                                                {group.length < 2 && <td className="border-top-0 padding-bottom-0" />}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return StateOfChargeComponentWithEmptyState;
        }
    });

    return mappedData.type === 'UNKNOWN'
        ? StateOfChargeComponentWithEmptyState
        : Loadable.cata(mappedData, success, failure, loading, unknown);
};

const groupBatteryDetails = <T,>(items: T[]): T[][] => {
    return items.reduce((acc, _, i, arr) => (i % 2 === 0 ? [...acc, arr.slice(i, i + 2)] : acc), [] as T[][]);
};

const isMatchingEntityForDriver = (driverId: string | null) => (entity: EntityType) =>
    (entity?.drivers?.length === 0 && driverId === null) ||
    (entity?.drivers?.length === 1 && driverId === (entity.drivers as RawDriver[])?.[0]?.driverId);

const SelfLoadingBattery = ({
    dateRange,
    driverIds,
    vehicleIds,
}: {
    dateRange: DateRange;
    driverIds: (Id | null)[];
    vehicleIds: Id[];
}) => {
    const requestDataForOneDriver = driverIds.length === 1;
    const segmentBy = requestDataForOneDriver ? PerformSegmentBy.driver_and_vehicle : PerformSegmentBy.vehicle;

    const start = dateRange.start;
    const end = dateRange.end;

    const batteryDetailsData = usePerformData(BATTERY_DETAILS_REQUEST_ATTRIBUTES, {
        variables: { start, end, vehicleIds, segmentBy } as Variables,
        debounced: false,
    }) as LoadableType<EntityType[]>;

    const desiredData = Loadable.map(batteryDetailsData, entities =>
        requestDataForOneDriver ? entities.find(isMatchingEntityForDriver(driverIds[0])) : _.head(entities)
    ) as LoadableType<HydratedEntity>;

    return (
        <BatteryProvider dateRange={dateRange} driverIds={driverIds} vehicleIds={vehicleIds}>
            <Battery data={desiredData} vehicleIds={vehicleIds} dateRange={dateRange} />
        </BatteryProvider>
    );
};

export default SelfLoadingBattery;
